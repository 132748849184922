<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form ref="form" label-position="right" :inline="true" :model="listQuery" class="form">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="展示模块：">
                <el-select
                  v-model="moduleId"
                  clearable
                  placeholder="全部"
                  @change="getDisplayPositionList"
                >
                  <el-option
                    v-for="item in moduleList"
                    :key="item.moduleId"
                    :label="item.terminal + '->' + item.moduleName"
                    :value="item.moduleId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="展示位：">
                <el-select
                  v-model="listQuery.moduleIds"
                  clearable
                  collapse-tags
                  multiple
                  placeholder="全部"
                >
                  <el-option
                    v-for="item in displayPositionList"
                    :key="item.moduleId"
                    :label="item.location"
                    :value="item.location"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="区域：">
                <el-cascader
                  :options="cityList"
                  :props="props"
                  :show-all-levels="false"
                  v-model="listQuery.areaIds"
                  placeholder="全部"
                  collapse-tags
                  clearable
                />
              </el-form-item>
              <el-form-item label="分类：">
                <el-select
                  v-model="listQuery.categoryIds"
                  clearable
                  placeholder="全部"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in categoryList"
                    :key="item.categoryId"
                    :label="item.name"
                    :value="item.categoryId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="展示状态：">
                <el-select
                  v-model="listQuery.putStatuses"
                  collapse-tags
                  clearable
                  multiple
                  placeholder="全部"
                >
                  <el-option label="已保存" :value="0">已保存</el-option>
                  <el-option label="待上架" :value="1">待上架</el-option>
                  <el-option label="已上架" :value="2">已上架</el-option>
                  <el-option label="已下架" :value="3">已下架</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="展示时间：">
                <el-date-picker
                  v-model="showTimeArray"
                  type="datetimerange"
                  range-separator="～"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item label="最后修改时间：">
                <el-date-picker
                  v-model="updateTimeArray"
                  type="datetimerange"
                  range-separator="~"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item label="广告ID：">
                <el-input v-model="listQuery.advertId" placeholder="请输入准确信息" clearable />
              </el-form-item>
              <el-form-item label="标题：">
                <el-input v-model="listQuery.title" placeholder="请输入广告主题标题关键词" />
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                icon="el-icon-search"
                type="primary"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >查询
              </AuthorityComponent>
              <el-button @click="onReset" type="info" icon="el-icon-delete">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.createAdv']"
                type="primary"
                @click="onAdd"
                icon="el-icon-plus"
                >创建广告
              </AuthorityComponent>
              <el-button
                v-if="$route.meta.authority.button.setting"
                type="info"
                icon="el-icon-setting"
                @click="addjkSeting"
                >{{ $t('button.set_up') }}</el-button
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddngB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          @sort-change="handleSortChange"
        >
          <el-table-column :label="$t('searchModule.Advertising_ID')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.advertId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.title')"
            header-align="center"
            show-overflow-tooltip
            min-width="150px"
          >
            <template slot-scope="scope">
              <el-popover placement="left" popper-class="poperStyle" width="200" trigger="hover">
                <img :src="scope.row.imgUrl" style="width: 100%; height: 200px" alt />
                <img
                  slot="reference"
                  :src="scope.row.imgUrl"
                  style="width: 30px; height: 30px; vertical-align: middle; margin-left: 30px"
                  alt
                />
              </el-popover>
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.classification')" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.categoryId">{{ scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.region')" align="center" show-overflow-tooltip min-width="150px">
            <template slot-scope="scope">
              <span>{{ scope.row.areaNames }}</span>
            </template>
          </el-table-column>
          <el-table-column label="跳转链接" align="center">
            <template slot-scope="scope">
              <i v-if="scope.row.jumpUrlType === 2" class="el-icon-s-operation" />
              <i
                v-if="scope.row.jumpUrlType === 1 && scope.row.jumpUrl"
                class="el-icon-link link"
                @click="toLink(scope.row.jumpUrl)"
              />
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Display_module')" prop="moduleName" align="center" min-width="150px">
            <template slot-scope="scope">
              <span>{{ scope.row.moduleName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Display_position')" prop="moduleLocName" align="center" sortable="custom">
            <template slot-scope="scope">
              <span>{{ scope.row.moduleLocName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.state')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.putStatus | statusFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.last_Operator')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.updateUserName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.last_Change_time')"
            min-width="120px"
            align="center"
            prop="updateTime"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.updateTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="80"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-dropdown
                @command="handleCommand($event, scope.row)"
                v-if="
                  $route.meta.authority.button.detail ||
                  ($route.meta.authority.button.editAdv &&
                    (scope.row.putStatus === 0 || scope.row.putStatus === 3)) ||
                  ($route.meta.authority.button.lowerShelf &&
                    (scope.row.putStatus === 1 || scope.row.putStatus === 2))
                "
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.detail']"
                    command="detail"
                    >详情
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.editAdv']"
                    command="edit"
                    v-if="scope.row.putStatus === 0 || scope.row.putStatus === 3"
                    >编辑
                  </AuthorityComponent>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.lowerShelf']"
                    command="lowerShelf"
                    v-if="scope.row.putStatus === 1 || scope.row.putStatus === 2"
                    >下架
                  </AuthorityComponent>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import _ from "lodash";
export default {
  name: "advertManage",
  filters: {
    statusFilter(val) {
      const arr = ["已保存", "待上架", "已上架", "已下架"];
      return arr[val];
    },
  },
  watch: {
    showTimeArray() {
      if (!this.showTimeArray || this.showTimeArray.length === 0) {
        delete this.listQuery.showStartTime;
        delete this.listQuery.showEndTime;
        return;
      }
      this.listQuery.showStartTime = this.$moment(this.showTimeArray[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.listQuery.showEndTime = this.$moment(this.showTimeArray[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    updateTimeArray() {
      if (!this.updateTimeArray || this.showTimeArray.length === 0) {
        delete this.listQuery.updateStartTime;
        delete this.listQuery.updateEndTime;
        return;
      }
      this.listQuery.updateStartTime = this.$moment(this.updateTimeArray[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.listQuery.updateEndTime = this.$moment(this.updateTimeArray[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    moduleId(val) {
      if (!this.moduleId) {
        delete this.listQuery.moduleName;
        return;
      }
      if (!this.moduleList) {
        return;
      }
      const targetList = this.moduleList.filter((item) => {
        return item.moduleId === val;
      });
      if (targetList.length > 0) {
        this.listQuery.moduleName = targetList[0].terminal + "-" + targetList[0].moduleName;
      }
    },
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 15,
      total: 0,
      listQuery: {},
      cityList: [],
      types: [],
      locs: [],
      props: {
        value: "areaId",
        label: "areaName",
        expandTrigger: "hover",
        multiple: true,
      },
      dialogFormVisible: false,
      dialogVisible: false,
      curTag: null,
      tagName: "",
      innerVisible: false,
      gridData: [],
      title: "创建标签",
      planTitle: "",
      loading: false,
      tableData: [],
      moduleList: [],
      moduleId: "",
      displayPositionList: [],
      categoryList: [],
      showTimeArray: [],
      updateTimeArray: [],
      authority: this.$route.meta.authority.button,
    };
  },
  mounted() {
    this.getModuleList();
    this.getCityList();
    this.getCategoryList();
    // this.searchData()
    console.log("广告权限=>", this.$route.meta.authority);
  },
  activated() {
    this.searchData();
  },
  methods: {
    addjkSeting() {
      this.$router.push("/categoryManage");
    },
    // 查询
    searchData() {
      this.loading = true;
      const areaIds = _.flattenDeep(this.listQuery.areaIds);
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        moduleName: this.listQuery.moduleName,
        advertId: this.listQuery.advertId,
        title: this.listQuery.title,
        moduleIds: this.listQuery.moduleIds,
        areaIds: areaIds,
        categoryIds: this.listQuery.categoryIds,
        putStatuses: this.listQuery.putStatuses,
      };
      if (this.updateTimeArray != null && this.updateTimeArray.length > 0) {
        // 不加上这个，如果只单选修改时间的话不会进入watch回调，原因不清楚
        this.listQuery.updateStartTime = this.updateTimeArray[0];
        this.listQuery.updateEndTime = this.updateTimeArray[1];
      }
      if (this.listQuery.locOrder) {
        data.locOrder = this.listQuery.locOrder;
      }
      if (this.listQuery.updateTimeOrder) {
        data.updateTimeOrder = this.listQuery.updateTimeOrder;
      }
      if (this.listQuery.showStartTime) {
        data.showStartTime = this.listQuery.showStartTime;
      }
      if (this.listQuery.showEndTime) {
        data.showEndTime = this.listQuery.showEndTime;
      }
      if (this.listQuery.updateStartTime) {
        data.updateStartTime = this.listQuery.updateStartTime;
      }
      if (this.listQuery.updateEndTime) {
        data.updateEndTime = this.listQuery.updateEndTime;
      }
      const opt = {
        url: "/acb/2.0/advert/list",
        method: "post",
        data: data,
        success: (res) => {
          this.loading = false;
          if (!res.value) {
            this.tableData = [];
            this.total = 0;
            return;
          }
          this.tableData = res.value.list;
          this.total = parseInt(res.value.total);
        },
        fail: (err) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 重置
    onReset() {
      this.moduleId = "";
      this.displayPositionList = [];
      this.listQuery = { moduleIds: [] };
      this.showTimeArray = [];
      this.updateTimeArray = [];
    },
    // 获取展示模块列表
    getModuleList() {
      const opt = {
        url: "/acb/2.0/advert/moduleList",
        method: "get",
        data: {},
        success: (res) => {
          this.moduleList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取展示位列表
    getDisplayPositionList(moduleId) {
      this.listQuery.moduleIds = [];
      this.displayPositionList = [];
      if (!moduleId) {
        return;
      }
      if (!this.moduleList) {
        return;
      }
      const targetList = this.moduleList.filter((item) => {
        return item.moduleId === moduleId;
      });
      if (targetList.length > 0) {
        const terminal = targetList[0].terminal;
        const moduleName = targetList[0].moduleName;
        const opt = {
          url: "/acb/2.0/advert/locationList",
          method: "get",
          data: {
            terminal: terminal,
            moduleName: moduleName,
          },
          success: (res) => {
            this.displayPositionList = res.value;
          },
        };
        this.$request(opt);
      }
    },
    // 获取投放城市列表
    getCityList() {
      const opt = {
        url: "/acb/2.0/advert/areaList",
        method: "get",
        data: {},
        success: (res) => {
          this.cityList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取分类列表
    getCategoryList() {
      const opt = {
        url: "/acb/2.0/advertCategory/list",
        method: "get",
        data: {},
        success: (res) => {
          this.categoryList = res.value;
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    toLink(url) {
      window.open(url);
    },
    // 创建广告
    onAdd() {
      this.$router.push({ path: "/advertManageEdit" });
    },
    // 处理列表按钮点击
    handleCommand(event, row) {
      switch (event) {
        case "detail":
          this.toDetail(row.advertId);
          break;
        case "edit":
          this.toEdit(row.advertId);
          break;
        case "lowerShelf":
          this.lowerShelf(row.advertId);
          break;
        default:
          break;
      }
    },
    // 进入详情页
    toDetail(advertId) {
      this.$router.push({ path: "/advertManageDetail", query: { advertId: advertId } });
    },
    // 进入编辑页
    toEdit(advertId) {
      this.$router.push({ path: "/advertManageEdit", query: { advertId: advertId } });
    },
    // 下架
    lowerShelf(advertId) {
      // url: '/acb/2.0/advert/createOrModify', 1 新增 2修改
      const opt = {
        url: "/acb/2.0/advert/advertUpdate",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: {
          advertId: advertId,
          putStatus: 3,
          operationType: 2,
          updateUser: parseInt(sessionStorage.userId),
        },
        success: (res) => {
          this.searchData();
          this.$alert("下架成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        },
      };
      this.$request(opt);
    },
    // 排序
    handleSortChange(value) {
      delete this.listQuery.locOrder;
      delete this.listQuery.updateTimeOrder;
      if (value.prop && value.order) {
        const prop = value.prop === "moduleLocName" ? "locOrder" : "updateTimeOrder";
        const order = value.order === "ascending" ? 1 : 2;
        this.listQuery[prop] = order;
      }
      this.searchData();
    },
  },
};
</script>

<style lang="stylus" scoped>
.link
  cursor pointer
  color #0f6eff
</style>
